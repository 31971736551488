import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import splashscreen from "../assets/images/splashscreen.jpg"
import { navigate } from "gatsby-plugin-intl"

import {isBrowser} from "react-device-detect"

class IndexPage extends React.Component {
  componentDidMount () {
    if(isBrowser) {
      navigate("/home")
    } else {
      setTimeout(() => {
        navigate("/home")
      }, 4000);
    }
  }

  render () {
    return (
    <Layout>
      <SEO title="Home" />

      <div className="row">
        <img src={splashscreen} className="splashscreen" alt="Splash Screen Nissan"/>
      </div>
    </Layout>
    )
  }
}

export default IndexPage
